@import "./react-input-range/scss/input-range/_input-range-variables.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");

.relay-button {
  $slider-size: 34px;
  $clearBlue: rgb(38, 105, 255);
  $gray: rgb(79, 95, 109);
  margin-left: 4px;
  margin-top: 3px;

  box-sizing: border-box;

  width: 140px;
  height: 34px;
  border-radius: 30px;
  border: 6px solid rgb(248, 248, 250);

  display: flex;
  justify-content: flex-start;
  position: relative;
  background-color: white;
  background-clip: content-box;

  &.success-theme {
    background-color: rgb(109, 193, 16);
    border-color: rgba(109, 193, 16, 0.4);
  }

  &.error-theme {
    background-color: rgb(245, 166, 35);
    border-color: rgba(245, 166, 35, 0.4);
  }

  .value-input-container {
    position: absolute;
    z-index: 1;
    min-width: 50px;
    height: 100%;
    align-self: center;
    overflow: hidden;
    padding-left: $slider-size;

    display: flex;
    flex-direction: row;
    align-items: center;

    .value-input-currency {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      color: $gray;
      user-select: none;
    }

    .value-input {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: bold;

      height: 100%;
      width: 65px;
      color: $gray;

      background: transparent;
      border: 0;
      padding-left: 5px;

      &:focus {
        outline: none;
      }

      &_display {
        font-size: 14px;
        line-height: 22px;
        flex: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
      }

      &_editable {
        cursor: text;
      }
    }

    .loading-title {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      color: rgb(144, 148, 156);
    }

    .success-title {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      width: 60px;
      color: white;
    }

    .error-title {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      width: 80px;
      color: white;
    }

    .success-title-value {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: white;
      padding-left: 5px;
      overflow: hidden;
      width: 30px;
      text-overflow: ellipsis;
    }
  }

  .input-range__track {
    height: 24px;
    width: 88%;
    border-radius: 24px;
    position: relative;
    background: white;
  }

  .input-range__slider {
    background: rgb(38, 105, 255);
    border: none !important;
    width: $slider-size;
    height: $slider-size;
    top: -20px;
    left: -1px;
    position: absolute;
    background-image: url("data-url:./images/relay.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0.5px 1px 1px 0.5px rgba(0, 0, 0, 0.29);

    z-index: 2;

    &:active {
      transform: none;
    }
  }

  .input-range__track--background {
    top: 10%;
  }

  .input-range__track--active {
    background: rgb(107, 161, 255);
    border-radius: 24px 0 0 24px;
    z-index: 2;

    transition: $input-range-track-transition, background-color 400ms linear;
  }

  .slider-wrapper {
    width: 100%;
    height: 100%;
  }

  .success-theme {
    .input-range__track {
      background-color: rgb(109, 193, 16);
      width: 100%;
    }

    .input-range__slider {
      background-image: url("data-url:./images/success.png");
      background-size: 45%;
      background-repeat: no-repeat;
      background-position: center;
      background-color: rgb(109, 193, 16);
      box-shadow: 0.5px 1px 1px 0.5px rgba(0, 0, 0, 0.29);
    }
  }

  .completed-theme {
    .input-range__track--active {
      background-color: $clearBlue;
    }
  }

  .empty-theme {
    .input-range__track {
      background-color: white;
      width: 100%;
      transition: none;
    }

    .input-range__slider {
      @extend .input-range__slider;
      opacity: 0.5;
      cursor: initial;
    }
  }

  .loading-theme {
    .input-range__track {
      background-color: rgb(215, 215, 219);
      width: 100%;
    }

    .input-range__slider {
      background-image: url("data-url:./images/loading.png");
      background-size: 45%;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $clearBlue;
      animation: rotate 5s linear infinite;
      backface-visibility: hidden;
      box-shadow: 0.5px 1px 1px 0.5px rgba(0, 0, 0, 0.29);
      cursor: initial;
    }
  }

  .error-theme {
    .input-range__track {
      background-color: rgb(245, 166, 35);
      width: 100%;
    }

    .input-range__slider {
      background-image: url("data-url:./images/error.png");
      background-size: 45%;
      background-repeat: no-repeat;
      background-position: center;
      background-color: rgb(245, 166, 35);
      box-shadow: 0.5px 1px 1px 0.5px rgba(0, 0, 0, 0.29);
      cursor: initial;
    }
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
